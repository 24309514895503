import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useHref,
  useLoaderData,
  useNavigate,
  useNavigation,
  useRouteError,
  useRouteLoaderData,
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { QueryClientProvider } from '@tanstack/react-query';
import type { ToastContent } from '@venncity/block';
import { ToastRegion, toastQueue, useToastQueue, RouterProvider } from '@venncity/block';
import { isEmpty, isFunction } from 'lodash-es';
import NProgress from 'nprogress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { NavigateOptions } from 'react-router-dom';
import { useChangeLanguage } from 'remix-i18next/react';
import Smartlook from 'smartlook-client';
import { z } from 'zod';
import { badRequest } from '~/utils/http.server';
import { ErrorPage } from './components/error-page';
import { analytics, useAnalytics } from './lib/analytics';
import { requireLogin } from './lib/auth.server';
import { localeCookie } from './lib/cookies.server';
import { i18next } from './lib/i18next.server';
import { getSession, sessionHeaders, sessionStorage } from './lib/session.server';
import 'nprogress/nprogress.css';
import '~/styles/app.css';
import '~/styles/fonts.css';
import { queryClient } from './lib/cache.client';
import { createVennGraphClient } from './lib/venn-graph-client.server';

declare module 'react-aria-components' {
  interface RouterConfig {
    routerOptions: NavigateOptions;
  }
}

export const meta: MetaFunction = () => [{ title: 'Venn Experience Hub' }];

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getSession(request);
  const message = session.get('message');

  const locale = await i18next.getLocale(request);

  const isProd = process.env.NODE_ENV === 'production';

  const headers = new Headers();

  headers.append('Set-Cookie', await sessionStorage.commitSession(session));
  headers.append('Set-Cookie', await localeCookie.serialize(locale));

  return json(
    {
      message,
      locale,
      ENV: {
        SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
        ENVIRONMENT: isProd ? process.env.ENVIRONMENT : 'dev',
        LOG_LEVEL: process.env.LOG_LEVEL,
        VENN_GRAPH_URL: process.env.VENN_GRAPH_URL,
        GRAPHQL_API: process.env.GRAPHQL_API,
        NODE_ENV: process.env.NODE_ENV,
        LOCIZE_API_KEY: isProd ? undefined : process.env.LOCIZE_API_KEY,
        SMARTLOOK_API_KEY: process.env.SMARTLOOK_API_KEY,
      },
      SESSION: {
        LOGIN_METHOD: session.get('login-method'),
      },
    },
    { headers },
  );
}

export async function action({ request }: ActionFunctionArgs) {
  await requireLogin(request);

  const formData = await request.formData();

  const hood = formData.get('hood');

  const AppContextSchema = z.object({
    hood: z.string().min(1),
  });

  try {
    const validated = await AppContextSchema.parseAsync({
      hood,
    });

    const client = await createVennGraphClient(request);

    const { community } = await client.query({
      community: {
        __args: {
          id: validated.hood,
        },
        timezone: true,
      },
    });

    const session = await getSession(request);

    session.set('app-context', {
      type: 'neighborhood',
      hood: validated.hood,
    });

    session.set('current-hood-timezone', community.timezone);

    return json({ success: true }, { headers: await sessionHeaders(session) });
  } catch (error) {
    return badRequest({ success: false, errors: {} });
  }
}

function App() {
  const loaderData = useLoaderData<typeof loader>();
  useChangeLanguage(loaderData.locale);
  const navigation = useNavigation();
  const toast = useToastQueue(toastQueue);
  const { pageName, handleOnCloseEvent, onFormSubmit } = useAnalytics();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isFunction(NProgress.done) || !isFunction(NProgress.start)) return;

    if (navigation.state === 'loading' && !navigation.formMethod) {
      NProgress.start();
    } else {
      NProgress.done();
    }

    return () => {
      NProgress.done();
    };
  }, [navigation.formMethod, navigation.state]);

  React.useEffect(() => {
    if (loaderData.ENV.SMARTLOOK_API_KEY) {
      Smartlook.init(loaderData.ENV.SMARTLOOK_API_KEY);
    }
  }, [loaderData.ENV.SMARTLOOK_API_KEY]);

  React.useEffect(() => {
    if (loaderData.ENV.SEGMENT_WRITE_KEY) {
      analytics.load({
        writeKey: loaderData.ENV.SEGMENT_WRITE_KEY,
      });
    }
  }, [loaderData.ENV.SEGMENT_WRITE_KEY]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', handleOnCloseEvent);
    return () => {
      window.removeEventListener('beforeunload', handleOnCloseEvent);
    };
  }, [handleOnCloseEvent]);

  React.useEffect(() => {
    if (loaderData.message) {
      const content: ToastContent = {
        title: loaderData.message.title,
        variant: loaderData.message.variant,
      };

      if (loaderData.message.link) {
        content.action = (
          <Link
            className="font-medium text-white/80 underline hover:text-white"
            reloadDocument
            to={loaderData.message.link.href}>
            {loaderData.message.link.text}
          </Link>
        );
      }

      if (isEmpty(toastQueue.visibleToasts)) {
        toastQueue.add(content, { timeout: 5000 });
      }
    }
  }, [loaderData.message]);

  return (
    <>
      <div
        className="flex h-full w-full flex-1 flex-col"
        onSubmit={(e) => onFormSubmit(e, pageName)}>
        <RouterProvider navigate={navigate} useHref={useHref}>
          <QueryClientProvider client={queryClient}>
            <Outlet />
          </QueryClientProvider>
        </RouterProvider>
      </div>
      <ToastRegion state={toast} />
    </>
  );
}

export default Sentry.withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError() as { message?: string; status?: number } | undefined;

  console.error(error);

  Sentry.captureRemixErrorBoundaryError(error);

  return <ErrorPage message={error?.message} status={error?.status} />;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const loaderData = useRouteLoaderData<typeof loader>('root');
  const { i18n } = useTranslation();

  return (
    <html className="h-full" dir={i18n.dir()} lang={loaderData?.locale ?? 'en'}>
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <Meta />
        <Links />
      </head>
      <body className="h-full w-full antialiased">
        {children}
        {loaderData?.ENV && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
                window.ENV = ${JSON.stringify(loaderData?.ENV)}
              `,
            }}
          />
        )}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const handle = {
  i18n: 'translation',
};
