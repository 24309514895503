import { Link } from '@remix-run/react';
import { Heading, Text, button } from '@venncity/block';
import NotAuthImage from '~/images/401.png';
import NotFoundImage from '~/images/404.png';
import crashImage from '~/images/crash.png';

const errorMessageMap: Record<string, { title: string; subTitle: string; image: string }> = {
  general: {
    title: 'Hang On!',
    subTitle:
      'This page is undergoing maintenance and not accessible at the moment. We recommend refreshing the page later.',
    image: crashImage,
  },
  500: {
    title: 'Hang On!',
    subTitle:
      'This page is undergoing maintenance and not accessible at the moment. We recommend refreshing the page later.',
    image: crashImage,
  },
  400: { title: 'Bad request', subTitle: '', image: crashImage },
  401: {
    title: 'Authentication Required',
    subTitle: 'Please log in to access this page.',
    image: NotAuthImage,
  },
  403: {
    title: 'No permission for this page',
    subTitle: `Oops, it looks like you don't have permission to access this page. Don't worry, it's not your fault. Please log in to a different account or contact our support team if you believe you should have access.`,
    image: NotAuthImage,
  },
  404: {
    title: 'Lost the way?',
    subTitle:
      "Dang. The page you're requesting couldn't be found. Please go back to the main dashboard.",
    image: NotFoundImage,
  },
};

const NavigateButton = ({
  variant,
  navigateToRoot,
  text,
}: {
  variant?: 'primary' | 'secondary';
  navigateToRoot?: boolean;
  text?: string;
}) => {
  return (
    <Link className={button({ variant })} to={navigateToRoot ? '/' : ''}>
      {text || 'Reload'}
    </Link>
  );
};

export function ErrorPage({ message, status }: { message?: string; status?: number }) {
  const errorStatus: string | undefined = (status && `${status}`) || message?.split(':')[0];

  const {
    title: errorTitle,
    subTitle: errorDetails,
    image: errorImage,
  } = (errorStatus && errorMessageMap[errorStatus]) || errorMessageMap.general;

  const is4ErrorGroup = errorStatus?.startsWith('4');
  const isAuthError = errorStatus && ['401', '403'].includes(errorStatus);

  return (
    <div className="flex h-full w-full items-center justify-center p-10">
      <div className="mr-20 w-[414px]">
        {is4ErrorGroup && <Heading className="mb-4 text-8xl">{errorStatus}</Heading>}
        <Heading className="mb-4">{errorTitle}</Heading>

        <Text>{errorDetails}</Text>
        <div className="mt-8 flex">
          {isAuthError ? (
            <Link className={`mr-4 ${button()}`} prefetch="intent" to="login">
              Log In
            </Link>
          ) : (
            <NavigateButton
              navigateToRoot={errorStatus === '404'}
              text={errorStatus === '404' ? 'Back to Homepage' : undefined}
            />
          )}
          {errorStatus === '403' && (
            <NavigateButton navigateToRoot text="Back to Homepage" variant="secondary" />
          )}
        </div>
      </div>

      <img alt="error page" className="flex max-h-[632px] max-w-[632px]" src={errorImage} />
    </div>
  );
}
